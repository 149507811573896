import React from "react"
import Layout from "../components/layout"
import { Text, Title } from "gatsby-theme-material-foundry"
import { Box, Container } from "@material-ui/core"

function personvern(props) {
  return (
    <Layout dark>
      <Container maxWidth="md">
        <Box my={20} minHeight="50vh">
          <Title variant="h4" black>
            IPR og rettigheter til ideer
          </Title>
          <Text paragraph>
            Alle ideer som blir sendt inn via portalen vår vil i henhold til HVL
            sine IPR-retningslinjer tilhøre studenten eller studentgruppen som
            sendte den inn. Dersom ideen er utviklet i samspill med ansatte ved
            HVL gjelder imidlertid andre regler. Ideen vil aldri deles til
            utenforstående uten studentens eller studentgruppens samtykke. Ved
            spørsmål om dette, kontakt skape@hvl.no.
          </Text>
          <Title variant="h4" black>
            Personvernerklæring
          </Title>
          <Title variant="h5" black>
            Hva er en personvernerklæring?
          </Title>
          <Text paragraph>
            En personvernerklæring beskriver hvilke personopplysninger som
            behandles, hvordan de behandles, hvem som er ansvarlig for
            behandlingen, hvilke rettigheter du har og hvem du kan kontakte om
            personopplysningene dine. Denne personvernerklæringen beskriver
            hvordan HVL håndterer personopplysningene dine.
          </Text>
          <Text paragraph>
            Denne personvernerklæringen handler om hvordan HVL samler inn
            og bruker personopplysninger om deg på hvlskape.no. HVL er forpliktet til å
            behandle personopplysninger på en forsvarlig måte.
          </Text>
          <Text paragraph>
            Personopplysninger er opplysninger og vurderinger som kan knyttes
            til en enkeltperson. Dette kan f.eks. være navn, adresse,
            telefonnummer, e-postadresse, IP-adresse og adferdshistorikk.
          </Text>

          <Title variant="h5" black>
            Behandlingsansvarlig
          </Title>
          <Text paragraph>
            HVL er behandlingsansvarlig når vi behandler personopplysninger om
            deg.
          </Text>
          <Title variant="h5" black>
            Formål med behandling av personopplysninger
          </Title>
          <Text paragraph>
            HVL samler inn og lagrer persondata i forbindelse med ulike
            aktiviteter på hvlskape.no. Dette inkluderer innsending av
            idéer, møteforespørsel, deltakelse på online-konkurranser, ønske om
            innmelding i gründerteam, o.l.. Innsamlingen er nødvendig for å
            kunne gi rett respons til avsender.
          </Text>

          <Title variant="h5" black>
            Hvordan personopplysninger blir innhentet og lagret
          </Title>
          <Text paragraph>
            Personopplysningene blir samlet inn via skjemaer på nettsiden og
            sendes via epost-servere som drives av Twilo SendGrid til HVL sine interne
            systemer der data blir behandlet og lagret.
          </Text>
          <Title variant="h5" black>
            Hvilke personopplysninger som behandles
          </Title>
          <Text paragraph>
            Opplysninger som behandles er:
            <ul>
              <li>navn </li>
              <li>e-postadresse </li>
              <li>fakultets- og campustilhørighet </li>
              <li>klassetrinn/årskull </li>
              <li>lenke til linkedin profil</li>
              <li>e-postadresse og navn til andre medlemmer i teamet</li>
            </ul>
          </Text>
          
          <Text paragraph>
            HVL behandler kun direkte identifiserbare opplysninger. Dette
            er opplysninger som identifiserer deg som enkeltperson. HVL 
            benytter seg ikke av cookies på hvlskape.no.{" "}
          </Text>

          <Title variant="h5" black>
            Hvordan sikrer vi opplysningene
          </Title>
          <Text paragraph>
            Det er kun personell tilknyttet HVL Skape som har tilgang til
            informasjonen du gir oss og denne behandles konfidensielt. Vi vil
            ikke avsløre, selge, distribuere, leie ut, lisensiere, dele eller
            videresende opplysninger eller personlig informasjon til noen
            tredjepart.
          </Text>
          <Text paragraph>
            Vi skal bruke alle rimelige forsiktighetsregler for å påse at våre
            medarbeidere og databehandlere som har tilgang til opplysninger om
            deg, har adekvat informasjon for å sikre at de bare behandler disse
            opplysningene i samsvar med denne erklæringen og våre forpliktelser
            etter personvernlovgivningen.
          </Text>
          <Text paragraph>
            Personopplysninger blir sendt via e-mail til{" "}
            <a href="mailto:skape@hvl.no">skape@hvl.no</a> og blir lagret på HVL
            sine servere. Vi behandler personopplysningene så lenge det er
            nødvendig for utførsel av våre oppgaver. Dersom vår behandling er
            basert på ditt samtykke vil vi slette opplysninger om deg dersom
            samtykke trekkes tilbake.
          </Text>

          <Title variant="h5" black>
            Dine rettigheter
          </Title>
          <Title variant="h6" black>
            Rett til informasjon og innsyn
          </Title>
          <Text paragraph>
            Du har krav på å få informasjon om hvordan HVL behandler
            personopplysningene dine gjennom hvlskape.no. Denne personvernerklæringen er ment å
            inneholde den informasjonen du har rett til å få. Du har også rett
            til å få innsyn i alle personopplysninger som er registrert om deg 
            gjennom hvlskape.no. Du har også rett til å få utlevert en kopi av
            personopplysninger om det, dersom du ønsker det.
          </Text>
          <Title variant="h6" black>
            Rett til retting
          </Title>
          <Text paragraph>
            Du har rett til å få uriktige personopplysninger om deg rettet. Du
            har også rett til å få ufullstendige personopplysninger om deg
            supplert. Dersom du mener vi har registrert feil eller mangelfulle
            personopplysninger om deg, ber vi deg kontakte oss. Det er viktig at
            du begrunner og eventuelt dokumenterer hvorfor du mener
            personopplysningene er feil eller mangelfulle.
          </Text>
          <Title variant="h6" black>
            Rett til begrensning av behandling
          </Title>
          <Text paragraph>
            Du kan ha rett til å kreve at behandlingen av personopplysningene
            dine blir begrenset. Begrensning av personopplysninger vil si at
            personopplysningene fortsatt blir lagret, men at mulighetene for
            videre bruk og behandling blir begrensede. Dersom du mener at
            personopplysningene er feil eller mangelfulle, eller har protestert
            mot behandlingen, har du rett til å kreve at behandlingen av
            personopplysningene dine midlertidig blir begrenset. Det vil si at
            behandlingen blir begrenset inntil vi eventuelt har rettet
            personopplysningene dine, eller har fått vurdert om protesten din er
            berettiget. I andre tilfeller kan du også kreve en mer permanent
            begrensing av dine personopplysninger. For å ha rett til å kreve
            begrensing av dine personopplysninger, må vilkårene i
            personvernforordningen artikkel 18 være oppfylte. Dersom vi mottar
            en henvendelse fra deg om begrensing av personopplysninger, vil vi
            vurdere om lovens vilkår er oppfylte.{" "}
          </Text>
          <Title variant="h6" black>
            Rett til sletting
          </Title>
          <Text paragraph>
            Du kan ha rett til å kreve at vi sletter personopplysninger om deg.
            Retten til sletting er ikke en ubetinget rett, og hvorvidt du har
            rett til sletting må vurderes i lys av personopplysningsloven og
            personvernforordningen. Dersom du ønsker å få slettet
            personopplysningene dine, ber vi deg om å ta kontakt med oss. Det er
            viktig at du begrunner hvorfor du ønsker at personopplysningene blir
            slettet, og om mulig også opplyser om hvilke personopplysninger du
            ønsker å få slettet. Vi vil da vurdere om de rettslige vilkårene for
            å kreve sletting er oppfylt. Det kan være unntak i lovverket om
            retten til sletting. Dette kan for eksempel være tilfelle når vi er
            nødt til å lagre personopplysningene for å oppfylle en oppgave vi er
            pålagt etter universitets- og høyskoleloven, eller for å ivareta
            viktige samfunnsinteresser som arkivering, forskning og statistikk.
          </Text>
          <Title variant="h6" black>
            Rett til å protestere
          </Title>
          <Text paragraph>
            Du kan ha rett til å protestere mot behandlingen dersom du har et
            særskilt behov for å få stanset behandlingen av personopplysningene
            dine. Eksempler kan være dersom du har et beskyttelsesbehov,
            fortrolig adresse, eller lignende. Retten til å protestere er ikke
            en ubetinget rett, og den avhenger av hva som er det rettslige
            grunnlaget for behandlingen, og om du har et særskilt behov. Hvis du
            protesterer mot behandlingen, vil vi vurdere om vilkårene for å
            protestere er oppfylt. Dersom vi kommer til at du har rett til å
            protestere mot behandlingen, og at innsigelsen er berettiget, vil vi
            stanse behandlingen og du vil også kunne kreve sletting av
            opplysningene. Vær oppmerksom på at vi i enkelte tilfeller likevel
            kan gjøre unntak fra sletting, for eksempel hvis vi er nødt til å
            lagre personopplysningene for å oppfylle en oppgave vi er pålagt
            etter universitets- og høyskoleloven, eller for å ivareta viktige
            samfunnsinteresser.
          </Text>
          <Title variant="h6" black>
            Rett til å klage
          </Title>
          <Text paragraph>
            Dersom du mener vi ikke har behandlet personopplysningene på en
            korrekt og lovlig måte, eller hvis du mener at vi ikke har klart å
            oppfylle dine rettigheter, har du mulighet til å klage over
            behandlingen. Du finner informasjon om hvordan du kan kontakte oss
            under punktet nedenfor. Dersom vi ikke tar klagen din til følge, har
            du mulighet til å fremme klagen for Datatilsynet. Datatilsynet er
            ansvarlig for å kontrollere at norske virksomheter overholder
            bestemmelsene i personopplysningsloven og personvernforordningen ved
            behandling av personopplysninger.
          </Text>
          <Title variant="h5" black>
            Kontaktinformasjon
          </Title>
          <Title variant="h6" black>
            Behandlingsansvarlig
          </Title>
          <Text paragraph>
            Dersom du ønsker å benytte deg av dine rettigheter som er omtalt i
            punktet om «Dine rettigheter» ovenfor, eller du har
            spørsmål/kommentarer til denne erklæringen, kan du kontakte oss:{" "}
            <a href="mailto:skape@vl.no">skape@hvl.no</a>
          </Text>
          <Title variant="h6" black>
            Personvernombudet
          </Title>
          <Text paragraph>
            Personvernombudet kan kontaktes angående alle spørsmål om behandling
            av dine personopplysninger og om utøvelsen av dine rettigheter.
            Personvernombudet kan nås via e-post{" "}
            <a href="mailto:personvernombud@hvl.no">personvernombud@hvl.no</a>.
          </Text>
        </Box>
      </Container>
    </Layout>
  )
}

export default personvern
